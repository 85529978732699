import ThemisRole from "@/components/role"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { ROLE } from "@/constants/bread-crumbs/role"

export default {
  name      : "Role",
  components: { ThemisRole },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      updateRole: "roles/updateRole",
      notify    : "shared/notify"
    }),
    ...mapMutations({
      setRoleUpdateError: "roles/setRoleUpdateError",
      setBreadCrumbs    : "shared/setBreadcrumbs"
    }),
    handleCancel() {
      this.$router.push({
        name  : "role",
        params: {
          id: this.role.id
        }
      })
    },
    async handleSave(event) {
      const roleToUpdate = JSON.parse(JSON.stringify(event))
      await this.updateRole(roleToUpdate)
    }
  },
  computed: {
    ...mapGetters({
      roles               : "roles/roles",
      isLoadingRoles      : "roles/isLoadingRoles",
      isUpdatingRole      : "roles/isUpdatingRole",
      allPermissions      : "permissions/permissions",
      roleUpdateError     : "roles/roleUpdateError",
      isNameUpdated       : "roles/isNameUpdated",
      isRoleDetailsUpdated: "roles/isRoleDetailsUpdated",
      isCustomRolesEnabled: "configurations/isCustomRolesEnabled"
    }),
    role() {
      return this.roles.find(role => role.id === +this.$route.params.id)
    }
  },
  watch: {
    isNameUpdated: {
      immediate: false,
      handler  : function() {
        this.setBreadCrumbs(ROLE({ params: { id: this.role.id } }, this.role.name))
      }
    },
    role: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.systemDefault) {
          this.$router.push({
            name: "roles"
          })
        }
      }
    },
    isRoleDetailsUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1977"
          })
        }
      }
    }
  }
}
