var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block",attrs:{"data-cy":"6168"}},[_c('v-row',{attrs:{"data-cy":"6169"}},[_c('v-col',{staticClass:"col-auto",attrs:{"data-cy":"6170"}},[_c('span',{ref:"role_information_title",staticClass:"pr-8 text-h6 font-weight-bold",attrs:{"data-cy":"6151"}},[_vm._v(_vm._s(_vm.$t("1896")))])]),_c('v-col',{staticClass:"col-auto",attrs:{"data-cy":"6171"}},[(_vm.showEditButton)?_c('v-btn',{ref:"button_role_edit",attrs:{"data-cy":"6152","color":"primary","outlined":""},on:{"click":_vm.handleEditRole}},[_c('v-icon',{attrs:{"data-cy":"6153","left":""}},[_vm._v("mdi-pencil")]),_c('span',{attrs:{"data-cy":"6165"}},[_vm._v(_vm._s(_vm.$t("1894")))])],1):_vm._e()],1)],1),_c('v-row',{attrs:{"data-cy":"6172"}},[_c('v-col',{staticClass:"col-md-5 py-1",attrs:{"data-cy":"6173"}},[(_vm.isDetailMode)?_c('themis-read-only',{ref:"themis_readonly_role_name",staticClass:"pb-4",attrs:{"data-cy":"6154","p-label":_vm._f("required")(_vm.$t('1891')),"p-value":_vm.localRole.name}}):_c('themis-input',{ref:"themis_input_role_name",attrs:{"data-cy":"6155","p-label":_vm._f("required")(_vm.$t('1891')),"p-rules":("required|duplicate:" + _vm.isRoleNameDuplicate + "|max:" + _vm.roleNameMaxCharacterLimit),"p-name":"1891","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"role_name",attrs:{"data-cy":"6156","placeholder":_vm.$t('1891'),"color":"secondary","background-color":"white","error-messages":errors,"clear-icon":"mdi-close-circle-outline","clearable":"","outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.localRole.name),callback:function ($$v) {_vm.$set(_vm.localRole, "name", $$v)},expression:"localRole.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"data-cy":"6174"}},[_c('v-col',{staticClass:"col-md-8 py-1",attrs:{"data-cy":"6175"}},[(_vm.isDetailMode)?_c('themis-read-only',{ref:"themis_readonly_role_description",staticClass:"pb-4",attrs:{"data-cy":"6157","p-label":_vm._f("required")(_vm.$t('1892')),"p-value":_vm.localRole.description}}):_c('themis-input',{ref:"themis_input_role_description",attrs:{"data-cy":"6158","p-label":_vm._f("required")(_vm.$t('1892')),"p-rules":("required|max:" + _vm.roleDescriptionMaxCharacterLimit),"p-name":"1892","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-textarea',{ref:"role_description",attrs:{"data-cy":"6159","placeholder":_vm.$t('1892'),"color":"secondary","background-color":"white","clear-icon":"mdi-close-circle-outline","error-messages":errors,"rows":"1","clearable":"","outlined":"","auto-grow":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.localRole.description),callback:function ($$v) {_vm.$set(_vm.localRole, "description", $$v)},expression:"localRole.description"}})]}}])})],1)],1),_c('div',{staticClass:"d-block py-4",attrs:{"data-cy":"6176"}},[_c('span',{ref:"role_permissions_title",staticClass:"text-h6",attrs:{"data-cy":"6160"}},[_vm._v(_vm._s(_vm.$t("1893")))]),_c('v-divider',{staticClass:"mb-4",attrs:{"data-cy":"6166"}}),_c('v-data-table',{ref:"table_role_permissions",staticClass:"elevation-1 table--role-permissions",attrs:{"data-cy":"6161","value":_vm.localSelectedPermissions,"items":_vm.localPermissions,"item-key":"name","headers":_vm.headerRolePermissions,"items-per-page":-1,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('span')]}},{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
return [_c('tr',{attrs:{"data-cy":"6179"}},[_c('td',{attrs:{"data-cy":"6180"}},[_c('span',{staticClass:"body-2",attrs:{"data-cy":"6181"}}),_vm._v(_vm._s(_vm.getDisplayNameForPermission(item.name)))]),_c('td',{attrs:{"data-cy":"6182"}},[_c('v-checkbox',{staticClass:"float-right ma-0",attrs:{"data-cy":"6183","input-value":isSelected,"disabled":!item.choosable,"hide-details":""},on:{"change":function($event){return _vm.onRowSelection(item, $event)}}})],1)])]}}])})],1),(_vm.isEditMode)?_c('div',{staticClass:"py-6 text-end",attrs:{"data-cy":"6167"}},[_c('v-btn',{ref:"button_role_cancel_edit",staticClass:"mr-6",attrs:{"data-cy":"6162","disabled":_vm.pIsRoleUpdating},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t("1897")))]),_c('v-btn',{ref:"button_role_save_edit",attrs:{"data-cy":"6163","color":"primary","type":"submit","loading":_vm.pIsRoleUpdating,"disabled":_vm.isSaveButtonDisabled},on:{"click":_vm.handleSave}},[_vm._v(_vm._s(_vm.$t("1898")))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }